<template>
  <div class="wrapper ">
    <ctheader></ctheader>
    <div class="container">
      <el-form ref="form" :model="form" label-width="100px" size="small">
        <el-form-item label="资源上传">
          <el-radio-group v-model="form.type">
            <el-radio :label="1">服务器</el-radio>
            <el-radio :label="2">华为云</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="服务器地址" v-if="form.type == 1">
          <el-input v-model="form.dir"></el-input>
        </el-form-item>
        <el-form-item label="是否强制更新">
          <el-radio-group v-model="form.is_cover">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择文件">
          <el-upload
            action="api/upload/file"
            :data="form"
            :file-list="fileList"
            :on-success="handleAvatarSuccess"
          >
            <el-button class="el-icon-plus">上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column fixed label="图片" width="140">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.url"
              fit="fill"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="url" label="链接"> </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import ctheader from "../../components/ctheader";

export default {
  name: "",
  components: { ctheader },
  mixins: [],
  props: {},
  data() {
    return {
      form: {
        action: "source",
        type: 1,
        is_cover: 1,
        dir: "op/test",
      },
      tableData: [],
      fileList: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    handleAvatarSuccess(res) {
      console.log(res);
      if (res.code == 0) {
        this.tableData.unshift({ url: res.data.url_http });
        // this.form.url = res.data.url;
        // this.form.resource_type == 1
        //   ? (this.fileList = [{ name: "", url: res.data.url }])
        //   : (this.fileList1 = [{ name: "", url: res.data.url }]);
      } else {
        this.$message.warning(res.msg);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background: #fff;
  min-height: 100vh;
  box-shadow: 0 0 10px 0 #e0e3e8;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  padding: 24px;
  .el-input {
    width: 300px;
  }
}
</style>
